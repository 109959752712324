import * as React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CarCard from "../components/car-card"
import { useTranslation } from "react-i18next"

const CarsPage = ({ data, location }) => {
  const { cars } = data.allStrapiCar;
  const { t } = useTranslation();

  let title = !cars || cars.length == 0 ? t('NoCarsForSale') :
    cars && cars.length == 1 ? t('1CarForSale') :
      cars && cars.length > 1 ? `${cars.length} ${t('CarsForSale')}` : 'Wagens';

  return (
    <Layout location={location}>
      <Seo
        title={`${t('Cars')}`}
        description={t('CarsMetaDescription')}
      />
      <div className="site-width page-title">
        <h1 className="">{title}</h1>
      </div>
      <section class="c-block c-block--first l-wrap" style={{ paddingTop: '48px' }}>
        <div className="site-width">
          <div class="c-block__content c-block__content--cars">
            {cars.map(c => {
              return <>
                <CarCard
                  car={c.node}
                  key={c.node.slug}
                />
              </>
            })}
          </div>
        </div>
      </section>
    </Layout >
  )
}

export default CarsPage


export const query = graphql`
      query CarsQuery($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
            node {
              ns
              data
              language
            }
          }
        }
        allStrapiCar(
          sort: {fields: published_at, order: DESC}
          filter: {sellingDate: {eq: null}, locale: {eq: $language}}
        )  {
          cars: edges {
            node {
              slug
              title
              mileage
              firstRegistration
              year
              price
              fuel_type {
                name
              }
              make {
                name
              }
              transmission {
                name
              }
              coverImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    `;